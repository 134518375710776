.custom-mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &.mat-form-field:not(.mat-form-field-appearance-legacy) {
    .mat-form-field-suffix .mat-icon-button {
      width: auto;
      height: auto;
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-flex {
      align-items: center;
    }

    .mat-form-field-flex,
    .mat-form-field-wrapper {
      margin: 0;
    }

    .mat-form-field-outline,
    .mat-form-field-suffix {
      top: 0;
    }

    .mat-select-arrow-wrapper {
      transform: none;
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-width: 1px;
      }
    }
  }

  .mat-form-field-infix {
    padding: 0;
    border-top-width: 0;
    line-height: 2.25;
  }

  .mat-form-field-label-wrapper {
    display: none;
  }

  &--width-300 {
    .mat-form-field-infix {
      width: 18.75rem;
    }
  }

  &--width-150 {
    .mat-form-field-infix {
      width: 9.375rem;
    }
  }

  &--width-100 {
    .mat-form-field-infix {
      width: 6.25rem;
    }
  }

  &--width-64 {
    .mat-form-field-infix {
      width: 4rem;
    }
  }

  &--block {
    width: 100%;

    .mat-form-field-infix {
      width: auto;
    }
  }
}

.mat-form-field-auto-width {
  width: 100%;

  .mat-form-field-infix {
    width: auto;
  }
}

.small-mat-form-field {
  .mat-form-field-infix {
    width: 6.25rem;
  }
}
