// @font-face {
//   font-family: Graphik;
//   src: url('https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Black-Web.eot');
//   src: url('https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Black-Web.woff') format('woff'),
//     url('https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Black-Web.woff2') format('woff2'),
//     url('https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Black-Web.ttf') format('truetype');
//   font-weight: 900;
// }

@font-face {
  font-family: Graphik;
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Bold-Web.eot");
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Bold-Web.woff2") format("woff2"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Bold-Web.woff") format("woff"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Bold-Web.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Graphik;
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Semibold-Web.eot");
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Semibold-Web.woff2") format("woff2"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Semibold-Web.woff") format("woff"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Semibold-Web.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Graphik;
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Medium-Web.eot");
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Medium-Web.woff") format("woff"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Medium-Web.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Graphik;
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Regular-Web.eot");
  src: url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Regular-Web.woff") format("woff"),
    url("https://storage.yandexcloud.net/home-cdn/fonts/Graphik-Regular-Web.ttf") format("truetype");
  font-weight: normal;
}
