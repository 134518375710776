/* stylelint-disable function-no-unknown */
@use "@angular/material" as mat;
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import "@angular/material/theming";
@include mat.core;

$pik-blue: (
  50: #e9f1fd,
  100: #c8dbfb,
  200: #a3c4f8,
  300: #7eacf5,
  400: #629af3,
  500: #4688f1,
  600: #3f80ef,
  700: #3775ed,
  800: #2f6beb,
  900: #2058e7,
  A100: #fff,
  A200: #ebf0ff,
  A400: #b8caff,
  A700: #9eb7ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$pik-orange: (
  50: #ffeae1,
  100: #fec9b3,
  200: #fea681,
  300: #fd824e,
  400: #fc6728,
  500: #fc4c02,
  600: #fc4502,
  700: #fb3c01,
  800: #fb3301,
  900: #fa2401,
  A100: #fff,
  A200: #ffefed,
  A400: #ffc0ba,
  A700: #ffa9a1,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$pik-green: (
  50: #f0f8f0,
  100: #d9eeda,
  200: #c0e3c2,
  300: #a7d8a9,
  400: #94cf96,
  500: #81c784,
  600: #79c17c,
  700: #6eba71,
  800: #64b367,
  900: #51a654,
  A100: #fff,
  A200: #d4ffd6,
  A400: #a1ffa5,
  A700: #88ff8c,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$lk-frontend-primary: mat.define-palette($pik-blue);
$lk-frontend-accent: mat.define-palette($pik-green);
$lk-frontend-warn: mat.define-palette($pik-orange);
$lk-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $lk-frontend-primary,
      accent: $lk-frontend-accent,
      warn: $lk-frontend-warn,
    ),
  )
);

@include mat.all-component-themes($lk-frontend-theme);

// Фикс вертикального положения всех иконок относительно текста
mat-icon {
  vertical-align: middle;
}

// Цвет маркера обязательного поля
.mat-focused .mat-form-field-required-marker {
  color: var(--color-warn);
}

.mat-form-field-required-marker {
  color: var(--color-warn);
}
