@font-face {
  font-family: ProbaPro;
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.eot");
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.woff2") format("woff2"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.woff") format("woff"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.eot");
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.woff2") format("woff2"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.woff") format("woff"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.ttf") format("truetype");
  font-display: fallback;
  font-weight: 300;
}
@font-face {
  font-family: ProbaPro;
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.eot");
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.woff2") format("woff2"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.woff") format("woff"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.eot");
  src: url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.woff2") format("woff2"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.woff") format("woff"),
    url("https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-display: fallback;
}
