// Материал и фиксы к нему
@import "./styles/material/material";
@import "./styles/material/custom-mat-form-field";
@import "./styles/material/custom-mat-autocomplete";
@import "./styles/material/custom-mat-menu";
@import "./styles/material/mat-dialog-request-details";
@import "~@ng-select/ng-select/themes/material.theme.css";

// Шрифты
@import "./styles/fonts/graphik";
@import "./styles/fonts/proba";

// Общие стили для всего приложения
@import "./styles/common/vars";
@import "./styles/common/typography";
@import "./styles/common/app-common";
@import "./styles/common/currency-symbol";
@import "./styles/common/drag-ghost";

// Стили для общих компонентов, либо для общих стилевых решений
@import "./styles/components/pik-header";
@import "./styles/components/settings-card";
@import "./styles/components/status";
@import "./styles/components/table-default";

// Правки для вендорных библиотек
@import "./styles/vendor/taiga";
@import "./styles/vendor/pik-header";
