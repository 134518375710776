:root {
  // Палетка цветов
  --color-primary: #1a75ff;
  --color-accent: #ffb11a;
  --color-warn: #ef4e23;
  --color-grey: #f2f2f2;
  --color-light-grey: #fbfbfb;
  --color-status-accepted: #219653;
  --color-status-warning: #ffb11a;
  --color-status-declined: #ef4e23;

  // Text
  --color-text-common: #1a1a1a;
  --color-text-common-invert: #fefefe;
  --color-text-light: #808080;
  --color-text-primary: #1a75ff;
  --color-text-warn: #ef4e23;
  --color-text-accent: #ffb11a;

  // Общие параметры
  --box-radius: 4px;
  --card-shadow: 4px 0 0 0 rgba(0, 0, 0, 0.6);

  // Переопределяем дефолтные стили от TaigaUI
  --tui-heading-font: Graphik, -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue",
    sans-serif;
  --tui-text-font: Graphik, -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue",
    sans-serif;
  --tui-font-text-xl: normal 1.25rem/1.75rem var(--tui-font-text);
  --tui-font-text-l: normal 1.125rem/1.75rem var(--tui-font-text);
  --tui-font-text-m: normal 1rem/1.5rem var(--tui-font-text);
  --tui-font-text-s: normal 0.875rem/1.25rem var(--tui-font-text);
  --tui-font-text-xs: normal 0.75rem/1rem var(--tui-font-text);
  --tui-font-heading-1: 500 3.125rem/3.5rem var(--tui-font-heading);
  --tui-font-heading-2: 500 2.75rem/3rem var(--tui-font-heading);
  --tui-font-heading-3: 500 2.25rem/2.5rem var(--tui-font-heading);
  --tui-font-heading-4: 500 1.75rem/2rem var(--tui-font-heading);
  --tui-font-heading-5: 500 1.5rem/1.75rem var(--tui-font-heading);
  --tui-font-heading-6: 700 1.25rem/1.5rem var(--tui-font-heading);
  --tui-primary: #4285f4;
  --tui-primary-hover: #46a6fd;

  // --tui-radius-xs: 0.25rem;
  // --tui-radius-s: 0.25rem;
  // --tui-radius-m: 0.5rem;
  // --tui-radius-l: 0.5rem;

  --tui-text-01: rgba(27, 31, 59, 0.8);
}
