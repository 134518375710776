html {
  font-size: 16px;

  @media all and (max-width: 1440px) {
    font-size: 15px;
  }

  @media all and (max-width: 1280px) {
    font-size: 14px;
  }

  @media all and (max-width: 1024px) {
    font-size: 16px;
  }

  box-sizing: border-box;
}

.pik-header {
  font-family: ProbaPro, sans-serif;
}
