body {
  font: var(--tui-font-text-m);
}

.text-accent {
  color: var(--color-text-accent);
}

.text-primary {
  color: var(--color-text-primary);
}

.text-warn {
  color: var(--color-text-warn);
}
