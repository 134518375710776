.drag-ghost {
  display: inline-block;
  vertical-align: middle;
  // width: 100%;
  // max-width: 20em;
  padding: 0.25em 0.75em;
  background-color: var(--tui-base-02);
  border-radius: 0.25em;
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.12);
}
