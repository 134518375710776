.table-default {
  width: 100%;
  border-collapse: collapse;

  &__head {
    color: var(--color-text-light);

    th {
      padding: 0.5em;
      font-weight: 500;
      border-bottom: 1px solid var(--color-grey);
      text-align: left;
    }
  }

  &__body {
    td {
      padding: 0.5em;
    }
  }
}
