.pik-status {
  display: inline;
  font-size: 0.875em;
  line-height: 1.4285714285714286;
  white-space: nowrap;

  &--accepted {
    color: var(--color-status-accepted);
  }

  &--warning {
    color: var(--color-status-warning);
  }

  &--declined {
    color: var(--color-status-declined);
  }
}
