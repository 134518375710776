tui-wrapper[data-appearance="remover"] {
  color: var(--tui-error-fill);

  &[data-state="hovered"] {
    background: var(--tui-error-bg);
  }

  &[data-state="pressed"] {
    background: var(--tui-error-bg);
  }

  &[data-state="disabled"] {
    color: #eaecee;
  }
}

tui-wrapper[data-appearance="positive"] {
  color: var(--tui-positive);

  &[data-state="hovered"] {
    color: var(--tui-positive-hover);
  }

  &[data-state="pressed"] {
    color: var(--tui-positive-hover);
  }

  &[data-state="disabled"] {
    color: #eaecee;
  }
}

tui-wrapper[data-appearance="negative"] {
  color: var(--tui-negative);

  &[data-state="hovered"] {
    color: var(--tui-negative-hover);
  }

  &[data-state="pressed"] {
    color: var(--tui-negative-hover);
  }

  &[data-state="disabled"] {
    color: #eaecee;
  }
}

tui-wrapper[data-appearance="subaction"] {
  color: var(--tui-neutral-fill);

  &[data-state="hovered"] {
    background: var(--tui-neutral-bg);
  }

  &[data-state="pressed"] {
    background: var(--tui-neutral-bg);
  }

  &[data-state="disabled"] {
    color: #eaecee;
  }
}

// Ребята из Тинька отказались от жирного текста на маленьких кнопках
// Наш дизайн, по-прежнему их содержит, поэтому перебиваю их обратно
// TODO: Внести в pik-taiga-ui
[tuibutton][data-tui-host-size="xs"] tui-wrapper,
[tuibutton][data-tui-host-size="s"] tui-wrapper {
  font-weight: bold;
}

[tuibutton][data-tui-host-size="m"] tui-wrapper,
[tuibutton][data-tui-host-size="l"] tui-wrapper,
[tuibutton][data-tui-host-size="xl"] tui-wrapper {
  font-weight: 500;
}

[tuitab] {
  &:last-child {
    margin-right: 0 !important;
  }
}

tui-dialog[data-size="l"] {
  width: 100% !important;
  max-width: 1200px !important;
}
