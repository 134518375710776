.app-body {
  max-width: 106.25em;
  margin: 0 auto;
  padding: 1.5em 1em;

  &__title {
    margin-bottom: 0.5em;
  }

  &__nav {
    display: block;
    margin-bottom: 1em;
  }

  // &__content {

  // }
}
